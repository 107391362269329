* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 25px;
}
.App {
  text-align: center;
  margin-top: 20px;

}

h3 {
  font-style: italic;
}
.lang-currency-group { 
  max-width: 350px; 
  width: 80%;
  margin: 10px auto 0;
  
  display: flex;
  justify-content: space-evenly;  
  
}

.langs button {
  margin-right: 5px;
  font-size: 0.7em;
  padding: 3px;
  font-weight: 500;
}

select {
  font-size: 0.7em;
  font-weight: 500;
  padding: 3px
}
.form-container {
  min-width: 150px;
  max-width: 350px;
  margin: 20px auto;  
  border: 1px solid rgba(80,50,60, 0.5);
  border-radius: 5px;
  padding: 5px;
  
}

.main-form {  
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: transparent;
}

.form-group{
  display: flex;
  flex-direction: column;  
  text-align: left;
  font-size: 0.7em;
  margin-bottom: 5px;
}

.form-group label {

  margin-bottom: 3px;
  font-size: 1em;
}

.form-group input {

  padding: 2px;
  font-size: 1.1em;
  font-weight: 600;
}
.radio-group {
  font-size: 0.8em;
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-evenly;
  
  padding: 5px 10px 10px 10px;

}

legend {
  padding: 0 5px 0 5px;
  font-size: 1em;
}

.inner-radio-group {
  width: 35%;  
  display: flex;  
  
}

.inner-radio-group label {
  margin-right: 5px;
  font-size: 0.7em;
}

input[type='submit'] {
  padding: 5px;
  background-color: green;
  color: white;
  border: 0;
  border-radius: 5px;
  font-size: 0.9em;
}

input[type='submit']:hover {
  background-color: rgb(67, 155, 67);
}
h4 {
  font-size: 1em;
}
p {
  font-size: 0.7em;
}

@media only screen and (max-width: 250px) {
  * {
    font-size: 13px;
  }
  .form-container {
    background-color: transperent;
  }
  .form-group{
    display: flex;
    flex-direction: column;  
    text-align: left;    
    margin-bottom: 5px;
  }
  .inner-radio-group label {
    
    margin-right: 5px;
    
  }
  
  .lang-currency-group{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .langs button {
    width: 100%;
    margin-right: 5px;
    margin-bottom: 5px;    
    padding: 3px
  }
  
  select {
    text-align: center;
    padding: 3px
  }
  input[type='submit'] {
    padding: 5px;
    background-color: green;
    color: white;
    border: 0;
    border-radius: 5px;    
  }
  
} 

.footer {
  margin-top: 20px;
}

